<template>
    <img :src="'/assets/'+fileName" :alt="$t(altId,['hello'])" :title="$t(altId,['hello'])" class="img-fluid banner-home">
</template>
<script>
  export default {
    name: 'Banner',
    props: {
      'altId': {
        type:String,
        required:true
      },
      'fileName': {
        type:String,
        required:true
      }
    }
  }
</script>

<style lang="scss">
.banner-home {
  width: 100%;
  height: 200px;
}
</style>