<template>
    <Button id="button-access-dash" @click="$router.push('/dashboard')"/>
</template>

<script>
import Button from "../atoms/ButtonSecond"
export default {
    name:"ButtonAccessDash",
    components: {Button},
}
</script>

<style lang="scss" scoped>

</style>