<template>
<div class="btn-ok-width">
    <Button id="button-access-map" @click="$router.push('/mapa')"/>
</div>
</template>

<script>
import Button from "../atoms/Button"
export default {
    name:"ButtonAccessMap",
    components: {Button},
}
</script>

<style lang="scss">
    .btn-ok-width{
        width: 410px!important;
        margin:auto auto;
    }
    @media only screen and (max-width: 600px) {
        .btn-ok-width {
            width: 170px!important;
        }
    }
</style>