<template>
  <Home />
</template>

<script>
import Home from "../templates/Home/Home"
export default {
    name:"PageHome",
    components:{Home}
}
</script>

<style>

</style>