<template>
    <div class="text-center">
        <button class="btn btn-nd" type="button">{{$t(id)}}</button>
    </div>
</template>

<script>
export default {
    name:"Button",
    props:{
        'id':{
            type:String,
            required:true
        }
    }
}
</script>

<style>
.btn-nd{
    font-family: 'Poppins', sans-serif!important;  
    background-color: #fff!important;
    border-radius: 16px!important;
    border: 1px solid #00853F;
    font-weight: bold!important;
    font-size: 1.125rem!important;
    line-height: 27px!important;
    color: #00853F!important;
    width: 410px!important;
}
.btn-nd:hover{
    color: #FFFFFF;
}
@media only screen and (max-width: 600px) {
    .btn-nd {
        width: 180px!important;
    }
}
@media screen and (max-width: 1279px) and (min-width: 601px) {
    .btn-nd{
        width: 210px!important;
    }
}
</style>