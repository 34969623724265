<template>
    <div class="row p-0 m-0 justify-content-center home-container" id="home">
        <div class="col-12 p-0">
            <Banner altId="banner-alt-home" fileName="banner_geral.jpg"/>
        </div>
        <div class="col-12 text-center d-block d-md-none mt-3">
            <ButtonAccessMap />
        </div>
        <div class="col-12 text-center d-block d-md-none my-3">
            <ButtonAccessDash />
        </div>
        <div class="col-md-8 mt-md-5 mt-3">
            <Paragraph id="about" class="sub-title"/>
            <Paragraph id="paragraph-1-home" />
            <Paragraph id="paragraph-2-home" />
            <Paragraph id="paragraph-3-home" />
            <Paragraph id="paragraph-4-home" />
        </div>
        <div class="col-md-8 my-3">
            <Paragraph id="data" class="sub-title"/>
            <Paragraph id="paragraph-5-home" />
            <Paragraph id="paragraph-6-home" />
            <Paragraph id="paragraph-7-home" />
            <Paragraph id="paragraph-8-home" />
            <Paragraph id="paragraph-9-home" />
            <Paragraph id="paragraph-10-home" />
        </div>
        <div class="col-12 text-center mt-3">
            <ButtonAccessMap />
        </div>
        <div class="col-12 text-center my-3 pb-5">
            <ButtonAccessDash />
        </div>
    </div>
</template>

<script>
import Banner from "../../atoms/Banner"
import ButtonAccessMap from "../../molecules/ButtonAccessMap"
import ButtonAccessDash from "../../molecules/ButtonAccessDash"
import Paragraph from "../../atoms/Paragraph"
export default {
    name:"TemplateHome",
    components:{Banner,Paragraph,ButtonAccessMap,ButtonAccessDash}
}
</script>

<style lang="scss">
.home-container {
  height: calc(100vh - 150px);
  overflow: auto;
    a {
        color: #00853F!important;
    }
}
.sub-title{
    font-weight: bold;
}
#home {
    // .padding-box {
    //     padding: 30px 300px;
    // }
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
}
</style>